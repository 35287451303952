import fetch from "auth/APIFetchInterceptor"

const MemberGroupService = {}

MemberGroupService.createMemberGroup = function (data) {
  return fetch({
    url: "/member/card-groups/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: data,
  })
}

MemberGroupService.getListMemberGroup = function (data) {
  let searchquery = {}
  if (!(data.Status === "" || data.Status === "All"))
    searchquery.Status = data.Status
  if (!(data.Name === "" || data.Name === "All")) searchquery.Name = data.Name

  return fetch({
    url: "/member/card-groups/find-all",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      Rows: data.rows ?? 20,
      Page: data.page ?? 1,
      Search: JSON.stringify(searchquery ?? "{}"),
    },
  })
}

MemberGroupService.getMemberGroup = function (data) {
  return fetch({
    url: `/member/card-groups/find-one`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { CardGroupId: data.GroupId },
  })
}

MemberGroupService.updateMemberGroup = function (data) {
  return fetch({
    url: `/member/card-groups/update`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: { ...data, CardGroupId: data.GroupId },
    method: "post",
  })
}

MemberGroupService.deleteMemberGroup = function (data) {
  return fetch({
    url: `/member/card-groups/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { CardGroupId: data.GroupId },
  })
}

export default MemberGroupService
