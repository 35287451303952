import axios from "axios"
import moment from "moment"
import { API2_BASE_URL } from "configs/AppConfig"
import { AUTH_TOKEN } from "redux/constants/Auth"
import Utils from "utils"
import fetch from "auth/APIFetchInterceptor"

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const api2Service = axios.create({
  baseURL: API2_BASE_URL,
  timeout: 60000,
  withCredentials: true,
})

const ReportService = {}

ReportService.getMemberList = function (data) {
  const searchQuery = {}
  const payload = {
    rows: Number.MAX_SAFE_INTEGER,
    page: 1,
    sort: "MembershipNo",
    sortDirection: "DESC",
  }
  if (data?.JoinDate) {
    payload.joinDateFrom = moment(data?.JoinDate[0]).format("YYYY-MM-DD")
    payload.joinDateTo = moment(data?.JoinDate[1]).format("YYYY-MM-DD")
  }
  if (data?.BirthMonth)
    payload.birthMonth = months[moment(data?.BirthMonth).month()]
  if (data?.AnniversaryMonth)
    payload.anniversaryMonth = months[moment(data?.AnniversaryMonth).month()]
  if (data?.MemberType && data?.MemberType !== "All") {
    payload.memberType = data.MemberType
  }
  if (data?.WithAddressYN) payload.withAddressYN = data.WithAddressYN
  if (data?.WithCategoryYN) payload.withCategoryYN = data.WithCategoryYN
  if (data?.WithPointYN) payload.withPointYN = data.WithPointYN
  if (data?.TotalRecordCountYN)
    payload.totalRecordCountYN = data.TotalRecordCountYN
  if (data?.MembershipNo) searchQuery.MembershipNo = data.MembershipNo
  if (data?.CardCategoryId) searchQuery.CardCategoryCode = data.CardCategoryId
  if (data?.FullName) payload.fullName = data.FullName.toUpperCase()
  if (data?.IdNo) searchQuery.IdNo = data.IdNo.toUpperCase()
  if (data?.ContactNo) searchQuery.ContactNo = data.ContactNo
  if (data?.Email) searchQuery.Email = data.Email
  if (data?.Status) searchQuery.Status = data.Status
  if (data?.RegisteredOutletCode && data?.RegisteredOutletCode?.length > 0)
    searchQuery.RegisteredOutletCode = data.RegisteredOutletCode
  if (data?.NearestOutletCode && data?.NearestOutletCode?.length > 0)
    searchQuery.NearestOutletCode = data.NearestOutletCode
  if (data?.City) searchQuery.City = data.City
  if (data?.State) searchQuery.State = data.State
  if (data?.PointBalanceFrom)
    searchQuery.PointBalanceFrom = data.PointBalanceFrom
  if (data?.PointBalanceTo) searchQuery.PointBalanceTo = data.PointBalanceTo
  if (data?.AllowNotification)
    searchQuery.AllowNotification = data.AllowNotification

  payload.search = JSON.stringify(searchQuery)

  if (data?.page) payload.page = data?.page ?? 1
  if (data?.rows) payload.rows = data?.rows ?? 10

  if (data.isGeneratingReport) {
    return api2Service({
      url: `/reporting/member-listing`,
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
      },
      data: payload,
    })
  } else {
    return fetch({
      url: `/reporting/member-listing`,
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
      },
      data: payload,
    })
  }
}

ReportService.getCustomerPerformanceReport = function (data) {
  const searchQuery = {}

  if (data?.Date) {
    searchQuery.DateFrom = moment(data?.Date[0]).format("YYYY-MM-DD")
    searchQuery.DateTo = moment(data?.Date[1]).format("YYYY-MM-DD")
  }
  if (data?.ReportOption) searchQuery.ReportOption = data.ReportOption
  if (data?.FullName) searchQuery.FullName = data.FullName.toUpperCase()
  if (data?.IdNo) searchQuery.IdNo = data.IdNo.toUpperCase()
  if (data?.OutletCode && data.OutletCode?.length)
    searchQuery.OutletCode = data.OutletCode
  if (data?.MembershipNo) searchQuery.MembershipNo = data.MembershipNo
  if (data?.ProductType) searchQuery.ProductType = data.ProductType
  if (data?.ProductGroup && data.ProductGroup?.length)
    searchQuery.ProductGroup = data.ProductGroup

  return fetch({
    url: `/reporting/customer-performance-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: searchQuery,
  })
}

ReportService.getCustomerRewardStatusReport = function (data) {
  const searchQuery = {}

  searchQuery.Search = {}

  if (data?.Date) {
    searchQuery.DateFrom = moment(data?.Date[0]).format("YYYY-MM-DD")
    searchQuery.DateTo = moment(data?.Date[1]).format("YYYY-MM-DD")
  }
  if (data?.ReportType) searchQuery.ReportType = data.ReportType
  if (data?.ReportOption) searchQuery.ReportOption = data.ReportOption
  if (data?.ReferenceCode)
    searchQuery.ReferenceCode = data.ReferenceCode.toUpperCase()
  if (data?.SortOption) searchQuery.SortOption = data.SortOption
  if (data?.SortDirection) searchQuery.SortDirection = data.SortDirection
  if (data?.OutletCode && data.OutletCode?.length)
    searchQuery.OutletCode = data.OutletCode
  if (data?.MembershipNo) searchQuery.Search.MembershipNo = data.MembershipNo
  if (data?.FullName) searchQuery.Search.FullName = data.FullName.toUpperCase()
  if (data?.Email) searchQuery.Search.Email = data.Email
  if (data?.VoucherNo)
    searchQuery.Search.VoucherNo = data.VoucherNo.toUpperCase()
  if (data?.IdNo) searchQuery.Search.IdNo = data.IdNo.toUpperCase()
  if (data?.RewardCode && data.RewardCode?.length)
    searchQuery.Search.Code = data.RewardCode

  return fetch({
    url: `/reporting/customer-reward-status-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: { ...searchQuery, Search: JSON.stringify(searchQuery.Search) },
  })
}

ReportService.getCustomerAnalysisReport = async function (data) {
  let { OutletCode, DateFrom, DateTo } = data

  let totalActiveMember,
    totalNewMember,
    totalSalesYesterday,
    totalTransaction,
    totalSelfRegistered,
    totalManualRegistered = 0
  let totalSalesByGender,
    totalSalesByRace,
    totalSalesByAge,
    totalSales = { count: 0, rows: [] }

  try {
    const activeMember = await fetch({
      url: `/reporting/customer-analysis-report`,
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
      },
      data: {
        ReportOption: "Join Analysis",
        MemberType: "Premium Member",
        AnalysisType: "Gender",
        ReportType: "Summary",
        OutletCode,
        DateFrom,
        DateTo,
      },
    })
    totalActiveMember = activeMember?.data?.rows
      ? parseFloat(
        activeMember?.data?.rows
          ?.map((s) => s.Total)
          ?.reduce((a, b) => a + b, 0)
      )?.toLocaleString()
      : 0
    totalSalesByGender = Utils.checkUserPrivilege("View Sales Information")
      ? activeMember?.data
      : {}

    const newMember = await fetch({
      url: `/reporting/customer-analysis-report`,
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
      },
      data: {
        ReportOption: "Join Analysis",
        MemberType: "Premium Member",
        AnalysisType: "Gender",
        ReportType: "Summary",
        OutletCode,
        DateFrom: moment().subtract(1, "d").startOf("day").format("YYYY-MM-DD"),
        DateTo: moment().subtract(1, "d").endOf("day").format("YYYY-MM-DD"),
      },
    })
    totalNewMember = newMember?.data?.rows
      ? parseFloat(
        newMember?.data?.rows?.map((s) => s.Total)?.reduce((a, b) => a + b, 0)
      )?.toLocaleString()
      : 0

    // TEMPORARY DISABLING Total Sales & Total Transaction. PLEASE DO NOT DELETE
    // const total =
    //   Utils.checkUserPrivilege("View Sales Information") &&
    //   (await fetch({
    //     url: `/reporting/customer-analysis-report`,
    //     method: "post",
    //     headers: {
    //       Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    //     },
    //     data: {
    //       ReportOption: "Sales Analysis",
    //       MemberType: "Premium Member",
    //       AnalysisType: "Gender",
    //       ReportType: "Summary",
    //       OutletCode,
    //       DateFrom,
    //       DateTo,
    //     },
    //   }))
    // totalSalesYesterday = total?.data?.rows
    //   ? parseFloat(
    //     total?.data?.rows?.map((s) => s.Sales)?.reduce((a, b) => a + b, 0)
    //   ).toLocaleString()
    //   : 0
    // totalTransaction = total?.data?.rows
    //   ? parseFloat(
    //     total?.data?.rows?.map((s) => s.Total)?.reduce((a, b) => a + b, 0)
    //   )?.toLocaleString()
    //   : 0

    const selfRegistered = await fetch({
      url: `/reporting/member-listing`,
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
      },
      data: {
        memberType: "Premium Member",
        joinDateFrom: DateFrom,
        joinDateTo: DateTo,
        withAddressYN: "N",
        withCategoryYN: "N",
        withPointYN: "N",
        totalRecordCountYN: "Y",
        search: JSON.stringify({
          RegisteredOutletCode: OutletCode,
          RegistrationSource: "Self Registration Portal",
        }),
        sort: "MembershipNo",
        sortDirection: "DESC",
      },
    })
    totalSelfRegistered = selfRegistered?.data?.count
      ? selfRegistered.data.count
      : 0

    const manualRegistered = await fetch({
      url: `/reporting/member-listing`,
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
      },
      data: {
        memberType: "Premium Member",
        joinDateFrom: DateFrom,
        joinDateTo: DateTo,
        withAddressYN: "N",
        withCategoryYN: "N",
        withPointYN: "N",
        totalRecordCountYN: "Y",
        search: JSON.stringify({
          RegisteredOutletCode: OutletCode,
          RegistrationSource: "CRM",
        }),
        sort: "MembershipNo",
        sortDirection: "DESC",
      },
    })
    totalManualRegistered = manualRegistered?.data?.count
      ? manualRegistered.data.count
      : 0

    const race =
      Utils.checkUserPrivilege("View Sales Information") &&
      (await fetch({
        url: `/reporting/customer-analysis-report`,
        method: "post",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
        },
        data: {
          ReportOption: "Join Analysis",
          MemberType: "Premium Member",
          AnalysisType: "Ethnicity",
          ReportType: "Summary",
          OutletCode,
          DateFrom,
          DateTo,
        },
      }))
    totalSalesByRace = race?.data

    const age =
      Utils.checkUserPrivilege("View Sales Information") &&
      (await fetch({
        url: `/reporting/customer-analysis-report`,
        method: "post",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
        },
        data: {
          ReportOption: "Join Analysis",
          MemberType: "Premium Member",
          AnalysisType: "Age",
          ReportType: "Summary",
          OutletCode,
          DateFrom,
          DateTo,
        },
      }))
    totalSalesByAge = age?.data

    const sales =
      Utils.checkUserPrivilege("View Sales Information") &&
      (await fetch({
        url: `/reporting/customer-analysis-report`,
        method: "post",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
        },
        data: {
          ReportOption: "Sales Analysis",
          MemberType: "Premium Member",
          ReportType: "Detail",
          OutletCode,
          DateFrom,
          DateTo,
        },
      }))
    totalSales = sales?.data

    return {
      totalActiveMember,
      totalNewMember,
      totalSalesYesterday,
      totalTransaction,
      totalSelfRegistered,
      totalManualRegistered,
      totalSalesByGender,
      totalSalesByRace,
      totalSalesByAge,
      totalSales,
    }
  } catch (e) {
    return {
      totalActiveMember,
      totalNewMember,
      totalSalesYesterday,
      totalTransaction,
      totalSelfRegistered,
      totalManualRegistered,
      totalSalesByGender,
      totalSalesByRace,
      totalSalesByAge,
      totalSales,
    }
  }
}

ReportService.getProductSalesAnalysisReport = function (data) {
  const searchQuery = {}

  if (data?.Date) {
    searchQuery.DateFrom = moment(data?.Date[0]).format("YYYY-MM-DD")
    searchQuery.DateTo = moment(data?.Date[1]).format("YYYY-MM-DD")
  }
  if (data?.AgeFrom) searchQuery.AgeFrom = Number(data.AgeFrom)
  if (data?.AgeTo) searchQuery.AgeTo = Number(data.AgeTo)
  if (data?.Gender) searchQuery.Gender = data.Gender
  if (data?.Race) searchQuery.Race = data.Race
  if (data?.OutletCode && data.OutletCode?.length)
    searchQuery.OutletCode = data.OutletCode
  if (data?.SalesPersonCode)
    searchQuery.SalesPersonCode = data.SalesPersonCode.toUpperCase()
  if (data?.ProductType) searchQuery.ProductType = data.ProductType
  if (data?.ProductGroup && data.ProductGroup?.length)
    searchQuery.ProductGroup = data.ProductGroup

  const payload = { ...searchQuery }

  return fetch({
    url: `/reporting/product-sales-analysis-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

ReportService.getMemberSalesAnalysisReport = function (data) {
  const searchQuery = {}

  if (data?.AgeFrom) searchQuery.AgeFrom = Number(data.AgeFrom)
  if (data?.AgeTo) searchQuery.AgeTo = Number(data.AgeTo)
  if (data?.SalesAmountFrom)
    searchQuery.SalesAmountFrom = Number(data.SalesAmountFrom)
  if (data?.SalesAmountTo)
    searchQuery.SalesAmountTo = Number(data.SalesAmountTo)
  if (data?.Gender) searchQuery.Gender = data.Gender
  if (data?.Race) searchQuery.Race = data.Race
  if (data?.Status) searchQuery.Status = data.Status
  if (data?.OutletCode && data.OutletCode?.length)
    searchQuery.OutletCode = data.OutletCode
  if (data?.MembershipNo) searchQuery.MembershipNo = data.MembershipNo
  if (data?.ProductType) searchQuery.ProductType = data.ProductType
  if (data?.ProductGroup && data.ProductGroup?.length)
    searchQuery.ProductGroup = data.ProductGroup
  if (data?.InvoiceYear) searchQuery.InvoiceYear = data.InvoiceYear
  if (data?.MemberCategory) searchQuery.MemberCategory = data.MemberCategory
  if (data?.Date) {
    searchQuery.TransactionDateFrom = moment(data?.Date[0]).format("YYYY-MM-DD")
    searchQuery.TransactionDateTo = moment(data?.Date[1]).format("YYYY-MM-DD")
  }

  const payload = { ...searchQuery }

  return fetch({
    url: `/reporting/member-sales-analysis-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

ReportService.getMemberProfileAmendmentReport = function (data) {
  const searchQuery = {}

  if (data?.Date) {
    searchQuery.DateFrom = moment(data?.Date[0]).format("YYYY-MM-DD")
    searchQuery.DateTo = moment(data?.Date[1]).format("YYYY-MM-DD")
  }

  if (data?.MemberType) searchQuery.MemberType = data.MemberType
  if (data?.MembershipNo) searchQuery.MembershipNo = data.MembershipNo
  if (data?.FullName) searchQuery.FullName = data.FullName
  if (data?.IdNo) searchQuery.IdNo = data.IdNo
  if (data?.ContactNo) searchQuery.ContactNo = data.ContactNo
  if (data?.Email) searchQuery.Email = data.Email
  if (data?.UpdatedFieldName)
    searchQuery.UpdatedFieldName = data.UpdatedFieldName
  if (data?.UpdatedById) searchQuery.UpdatedById = data.UpdatedById

  const payload = { ...searchQuery }

  return fetch({
    url: `/reporting/member-profile-amendment-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

ReportService.getCustomerTransactionReport = function (data) {
  const searchQuery = {}

  if (data?.Date) {
    searchQuery.TransactionDateFrom = moment(data?.Date[0]).format("YYYY-MM-DD")
    searchQuery.TransactionDateTo = moment(data?.Date[1]).format("YYYY-MM-DD")
  }
  if (data?.MembershipNo) searchQuery.MembershipNo = data.MembershipNo
  if (data?.CustomerIDNo) searchQuery.CustomerIDNo = data.CustomerIDNo
  if (data?.ContactNo) searchQuery.ContactNo = data.ContactNo
  if (data?.Email) searchQuery.Email = data.Email
  if (data?.SalesPersonCode)
    searchQuery.SalesPersonCode = data.SalesPersonCode.toUpperCase()
  if (data?.OutletCode) searchQuery.OutletCode = data.OutletCode
  if (data?.ProductType) searchQuery.ProductType = data.ProductType
  if (data?.ProductGroup) searchQuery.ProductGroup = data.ProductGroup
  if (data?.AmountFrom) searchQuery.AmountFrom = Number(data.AmountFrom)
  if (data?.AmountTo) searchQuery.AmountTo = Number(data.AmountTo)

  const payload = { ...searchQuery }

  return fetch({
    url: `/reporting/customer-transaction-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

ReportService.getNonMemberTransactionReport = function (data) {
  const searchQuery = {}

  if (data?.Date) {
    searchQuery.TransactionDateFrom = moment(data?.Date[0]).format("YYYY-MM-DD")
    searchQuery.TransactionDateTo = moment(data?.Date[1]).format("YYYY-MM-DD")
  }

  if (data?.Type) searchQuery.Type = data.Type
  if (data?.OutletCode && data.OutletCode?.length)
    searchQuery.OutletCode = data.OutletCode

  const payload = { ...searchQuery }

  return fetch({
    url: `/reporting/non-member-transaction-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

ReportService.getRepeatCustomerAnalysisReport = function (data) {
  const searchQuery = {}

  if (data?.OutletCode && data.OutletCode?.length)
    searchQuery.OutletCode = data.OutletCode
  if (data?.Date) {
    searchQuery.TransactionDateFrom = moment(data?.Date[0]).format("YYYY-MM-DD")
    searchQuery.TransactionDateTo = moment(data?.Date[1]).format("YYYY-MM-DD")
  }

  if (data?.ReportType) searchQuery.ReportType = data.ReportType

  const payload = { ...searchQuery }

  return fetch({
    url: `/reporting/repeat-customer-analysis-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

ReportService.getPremiumGiftRedemptionListing = function (data) {
  const searchQuery = {}

  if (data?.Date) {
    searchQuery.DateFrom = moment(data?.Date[0]).format("YYYY-MM-DD")
    searchQuery.DateTo = moment(data?.Date[1]).format("YYYY-MM-DD")
  }
  if (data?.Status) searchQuery.Status = data.Status
  if (data?.ReferenceCode)
    searchQuery.ReferenceCode = data.ReferenceCode.toUpperCase()
  if (data?.OutletCode) searchQuery.OutletCode = data.OutletCode
  if (data?.RewardCode) searchQuery.RewardCode = data.RewardCode
  if (data?.MembershipNo) searchQuery.MembershipNo = data.MembershipNo
  if (data?.IdNo) searchQuery.IdNo = data.IdNo.toUpperCase()

  const payload = { ...searchQuery }

  return fetch({
    url: `/reporting/premium-gift-redemption-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

ReportService.getInactiveMemberReportListing = function (data) {
  const searchQuery = {}

  if (data?.Date) {
    searchQuery.LastTransactionDateFrom = moment(data?.Date[0]).format(
      "YYYY-MM-DD"
    )
    searchQuery.LastTransactionDateTo = moment(data?.Date[1]).format(
      "YYYY-MM-DD"
    )
  }
  if (data?.MembershipNo) searchQuery.MembershipNo = data.MembershipNo
  if (data?.IdNo) searchQuery.IdNo = data.IdNo.toUpperCase()
  if (data?.ContactNo) searchQuery.ContactNo = data.ContactNo
  if (data?.Email) searchQuery.Email = data.Email
  if (data?.OutletCode && data.OutletCode?.length) {
    searchQuery.OutletCode = data.OutletCode
  }

  const payload = {
    Rows: data.rows ? data.rows : Number.MAX_SAFE_INTEGER,
    Page: data.page ? data.page : 1,
    ...searchQuery,
  }

  return fetch({
    url: `/reporting/inactive-member-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

ReportService.getReactiveMemberReportListing = function (data) {
  const searchQuery = {}

  if (data?.Date) {
    searchQuery.LastTransactionDateFrom = moment(data?.Date[0]).format(
      "YYYY-MM-DD"
    )
    searchQuery.LastTransactionDateTo = moment(data?.Date[1]).format(
      "YYYY-MM-DD"
    )
  }
  if (data?.MembershipNo) searchQuery.MembershipNo = data.MembershipNo
  if (data?.IdNo) searchQuery.IdNo = data.IdNo.toUpperCase()
  if (data?.ContactNo) searchQuery.ContactNo = data.ContactNo
  if (data?.Email) searchQuery.Email = data.Email
  if (data?.OutletCode && data.OutletCode?.length) {
    searchQuery.OutletCode = data.OutletCode
  }

  const payload = {
    Rows: data.rows ? data.rows : Number.MAX_SAFE_INTEGER,
    Page: data.page ? data.page : 1,
    ...searchQuery,
  }

  return fetch({
    url: `/reporting/reactive-member-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

ReportService.getMemberCategoryAnalysisReport = function (data) {
  const searchQuery = {}

  if (data?.Date) {
    searchQuery.DateFrom = moment(data?.Date[0]).format("YYYY-MM-DD")
    searchQuery.DateTo = moment(data?.Date[1]).format("YYYY-MM-DD")
  }
  if (data?.OutletCode && data.OutletCode?.length) {
    searchQuery.OutletCode = data.OutletCode
  }
  if (data?.ReportOption) searchQuery.ReportOption = data.ReportOption

  const payload = { ...searchQuery }

  return fetch({
    url: `/reporting/member-category-analysis-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

ReportService.getAuditTrailReport = function (data) {
  const searchQuery = {}

  if (data?.Date) {
    searchQuery.DateFrom = moment(data?.Date[0]).format("YYYY-MM-DD")
    searchQuery.DateTo = moment(data?.Date[1]).format("YYYY-MM-DD")
  }
  if (data?.UserId) searchQuery.UserId = data.UserId
  if (data?.ReportOption) searchQuery.ObjectType = data.ReportOption
  if (data?.ObjectId) searchQuery.ObjectId = data.ObjectId

  const payload = { ...searchQuery }

  return fetch({
    url: `/reporting/audit-trail-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

ReportService.getFeedbackAnalysisReport = function (data) {
  const searchQuery = {}

  if (data?.Date) {
    searchQuery.DateFrom = moment(data?.Date[0]).format("YYYY-MM-DD")
    searchQuery.DateTo = moment(data?.Date[1]).format("YYYY-MM-DD")
  }
  if (data?.FeedbackId) searchQuery.FeedbackId = data.FeedbackId
  if (data?.OutletCode && data.OutletCode?.length) {
    searchQuery.OutletCode = data.OutletCode
  }
  if (data?.ReportType) searchQuery.ReportType = data.ReportType

  const payload = { ...searchQuery }

  return fetch({
    url: `/reporting/feedback-analysis-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

ReportService.getCampaignTargetListReport = function (data) {
  const searchQuery = {}

  if (data?.CampaignCode) searchQuery.CampaignCode = data.CampaignCode
  if (data?.Date) {
    searchQuery.StartDateFrom = moment(data?.Date[0]).format("YYYY-MM-DD")
    searchQuery.StartDateTo = moment(data?.Date[1]).format("YYYY-MM-DD")
  }
  if (data?.CampaignCode) searchQuery.CampaignCode = data.CampaignCode
  if (data?.MembershipNo) searchQuery.MembershipNo = data.MembershipNo
  if (data?.IdNo) searchQuery.IdNo = data.IdNo
  if (data?.Status) searchQuery.Status = data.Status

  const payload = { ...searchQuery }

  return fetch({
    url: `/reporting/campaign-target-list-report`,
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
    data: payload,
  })
}

export default ReportService
