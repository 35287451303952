import fetch from "auth/APIFetchInterceptor"

const CustomerTypeService = {}

CustomerTypeService.getListCustomerType = function (data) {
  return fetch({
    url: "/customer-types/find-all",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
  })
}

export default CustomerTypeService
