import fetch from "auth/APIFetchInterceptor"

const PointStructureService = {}

PointStructureService.createPointStructure = function (data) {
  return fetch({
    url: "/point/structures/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: data,
  })
}
PointStructureService.createPointStructureDetails = function (data, id) {
  return fetch({
    url: `/point/structure-details/add`,
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: { ...data, StructureId: id },
  })
}
PointStructureService.getListPointStructure = function (data) {
  Object.keys(data.search).forEach((item) => {
    if (!data.search[item]) {
      delete data.search[item]
    }
  })

  return fetch({
    url: "/point/structures/find-all",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      Rows: data.rows ? data.rows : 20,
      Page: data.page ?? 1,
      Search: JSON.stringify(data.search ?? {}),
    },
  })
}

PointStructureService.getPointStructure = function (data) {
  return fetch({
    url: `/point/structures/find-one`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { StructureId: data.GroupId },
  })
}

PointStructureService.updatePointStructure = function (data) {
  let token = data.access_token
  delete data.access_token
  return fetch({
    url: `/point/structures/update`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { ...data, StructureId: data.StructureId },
    method: "post",
  })
}
PointStructureService.updatePointStructureDetails = function (data, id) {
  Object.keys(data).forEach((item) => {
    data[item] = data[item].toString()
  })
  let token = data.access_token
  delete data.access_token
  return fetch({
    url: `/point/structure-details/update`,
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { ...data, StructureDetailId: data.StructureDetailId },
  })
}
PointStructureService.deletePointStructure = function (data) {
  return fetch({
    url: `/point/structures/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { StructureId: data.StructureId },
  })
}

export default PointStructureService
