import moment from "moment"
import fetch from "auth/APIFetchInterceptor"

const PointExpiryRewardService = {}

PointExpiryRewardService.createPointExpiryReward = function (data) {
  return fetch({
    url: `/point/expiry-rewards/add`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

PointExpiryRewardService.getListPointExpiryRewardEligible = function (data) {
  const payload = {}

  if (data.MonthYear) {
    payload.ExpiryMonth = Number(moment(data.MonthYear).format("M"))
    payload.ExpiryYear = Number(moment(data.MonthYear).format("YYYY"))
  }
  if (data.MembershipNo) payload.MembershipNo = data.MembershipNo
  if (data.IdNo) payload.IdNo = data.IdNo
  if (data.ContactNo) payload.ContactNo = data.ContactNo
  if (data.Email) payload.Email = data.Email
  if (data.OutletCode) payload.OutletCode = data.OutletCode
  if (data.Status) payload.Status = data.Status

  return fetch({
    url: `/point/expiry-rewards/eligible`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: payload,
  })
}

export default PointExpiryRewardService
