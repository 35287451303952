import fetch from "auth/APIFetchInterceptor"

const FeedbackResponseService = {}

FeedbackResponseService.createFeedbackResponse = function (data) {
  return fetch({
    url: `/feedback/response/add`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

FeedbackResponseService.getListFeedbackResponse = function (data) {
  const searchQuery = {}
  if (!(data.ResponseId === "")) {
    searchQuery.ResponseId = data.ResponseId
  }
  if (!(data.Status === "" || data.Status === "All")) {
    searchQuery.Status = data.Status
  }

  return fetch({
    url: `/feedback/response/find-all`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      Rows: data.rows ? data.rows : 1000,
      Page: data.page ? data.page : 1,
      Search: JSON.stringify(searchQuery ?? {}),
    },
  })
}

FeedbackResponseService.getOneFeedbackResponse = function (data) {
  return fetch({
    url: "/feedback/question/find-one",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      ResponseId: data?.ResponseId,
    },
  })
}

FeedbackResponseService.updateFeedbackResponse = function (data) {
  return fetch({
    url: `/feedback/response/update`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

FeedbackResponseService.deleteFeedbackResponse = function (data) {
  return fetch({
    url: `/feedback/response/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      ResponseId: data?.ResponseId,
    },
  })
}

export default FeedbackResponseService
