import React, { useEffect } from "react"
import { Modal, Form, Input, Row, Col, Button, Select } from "antd"

const CustomerLeadSourceModal = ({
  title,
  visible,
  setShowModal,
  data,
  setData,
  onSubmit,
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(data)
  }, [data, form])

  const onCancelHandler = () => {
    setShowModal(false)
    clearFields()
  }

  const clearFields = () => {
    setData({})
    setTimeout(() => {
      form.resetFields()
    }, 1000)
  }

  const submitHandler = () => {
    onSubmit()
    clearFields()
    setShowModal(false)
  }

  const pullLeft = {
    labelAlign: "left",
    wrapperCol: { span: 20 },
    labelCol: { span: 6 },
  }

  const styleFormLabel = {
    width: 100,
  }

  return (
    <Modal
      title={title ?? "Customer Lead Source"}
      visible={visible}
      okText={"Save"}
      onCancel={onCancelHandler}
      destroyOnClose
      footer={null}
    >
      <Form
        {...pullLeft}
        name="formCustomerLeadSource"
        initialValues={data}
        onFinish={submitHandler}
        autoComplete={"off"}
        form={form}
      >
        <Form.Item
          label={<label style={styleFormLabel}>Code</label>}
          name="Code"
          onChange={(e) => setData({ ...data, Code: e.target.value })}
          required
          rules={[
            {
              required: true,
              message: "Please input lead source code",
            },
            () => ({
              validator(_, value) {
                if (value && value.length >= 1 && value.length <= 20) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error("Code must be between 1 and 20 characters length")
                )
              },
            }),
          ]}
        >
          <Input style={{ textTransform: "uppercase" }} />
        </Form.Item>

        <Form.Item
          label={<label style={styleFormLabel}>Name</label>}
          name="Name"
          onChange={(e) => setData({ ...data, Name: e.target.value })}
          required
          rules={[
            {
              required: true,
              message: "Please input lead source name",
            },
            () => ({
              validator(_, value) {
                if (value && value.length >= 3 && value.length <= 200) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error("Name must be between 3 and 200 characters length")
                )
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<label style={styleFormLabel}>Type</label>}
          name="Type"
          required
          rules={[
            {
              required: true,
              message: "Please select the lead source type",
            },
          ]}
        >
          <Select
            value={data.Type}
            onChange={(value) => setData({ ...data, Type: value })}
          >
            <Select.Option value={"Event"}>Event</Select.Option>
            <Select.Option value={"Non-Event"}>Non-Event</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={<label style={styleFormLabel}>Status</label>}
          name="Status"
          required
          rules={[
            {
              required: true,
              message: "Please select the lead source status",
            },
          ]}
        >
          <Select
            value={data.Status}
            onChange={(value) => setData({ ...data, Status: value })}
          >
            <Select.Option value={"Active"}>Active</Select.Option>
            <Select.Option value={"Inactive"}>Inactive</Select.Option>
          </Select>
        </Form.Item>

        <Row gutter={{ md: 10 }} justify={"end"}>
          <Col>
            <Button onClick={onCancelHandler}>Cancel</Button>
          </Col>

          <Col>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default CustomerLeadSourceModal
