import {
  SET_CREATE_AUDIT_LOG,
  SET_LIST_AUDIT_LOG,
} from "redux/constants/AuditLog"

const initialState = {
  auditLogList: {
    count: 0,
    rows: [],
  },
}

const auditLog = (state = initialState, action) => {
  switch (action.type) {
    case SET_LIST_AUDIT_LOG:
      return {
        ...state,
        auditLogList: action.payload,
      }

    case SET_CREATE_AUDIT_LOG:
      return {
        ...state,
        auditLogList: {
          count: state.auditLogList.count + 1,
          rows: [action.payload, ...state.auditLogList.rows],
        },
      }

    default:
      return state
  }
}

export default auditLog
