import fetch from "auth/APIFetchInterceptor"

const ImportLeadsProspectsService = {}

ImportLeadsProspectsService.findExistingLeadSource = function (data) {
  const searchQuery = {}
  if (!(data.IdNo === "")) {
    searchQuery.IdNo = data.IdNo
  }
  if (!(data.ContactNo === "")) {
    searchQuery.ContactNo = data.ContactNo
  }

  return fetch({
    url: `/customer/customers/find-all`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      Rows: data.rows ? data.rows : 1000,
      Page: data.page ? data.page : 1,
      Search: JSON.stringify(searchQuery ?? {}),
    },
  })
}

ImportLeadsProspectsService.createLeadSourceProspect = function (data) {
  return fetch({
    url: "/customer/customers/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: data,
  })
}

ImportLeadsProspectsService.getListLeadSourceProspects = function (data) {
  const searchQuery = {}
  if (!(data.Code === "")) {
    searchQuery.Code = data.Code?.toUpperCase()
  }
  if (!(data.Name === "")) {
    searchQuery.Name = data.Name
  }
  if (!(data.Type === "")) {
    searchQuery.Type = data.Type
  }
  if (!(data.Status === "" || data.Status === "All")) {
    searchQuery.Status = data.Status
  }

  return fetch({
    url: `/customer/lead-sources/find-all`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      Rows: data.rows ? data.rows : 1000,
      Page: data.page ? data.page : 1,
      Search: JSON.stringify(searchQuery ?? {}),
    },
  })
}

ImportLeadsProspectsService.getOneLeadSourceProspects = function (data) {
  return fetch({
    url: "/customer/lead-sources/find-one",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      LeadSourceId: data?.LeadSourceId,
    },
  })
}

export default ImportLeadsProspectsService
