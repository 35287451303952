import {
  SET_CREATE_CUSTOMER_LEAD_SOURCE,
  SET_DELETE_CUSTOMER_LEAD_SOURCE,
  SET_LIST_CUSTOMER_LEAD_SOURCE,
  SET_CUSTOMER_LEAD_SOURCE_DETAIL,
  SET_UPDATE_CUSTOMER_LEAD_SOURCE,
} from "redux/constants/CustomerLeadSource"

const initialState = {
  customerLeadSourceList: {
    isCustomerLeadSourceFetched: false,
    isCustomerLeadSourceLoading: false,
    count: 0,
    rows: [],
  },
  customerLeadSourceDetail: {},
}

const customerLeadSource = (state = initialState, action) => {
  switch (action.type) {
    /*Customer Lead Source*/
    case SET_LIST_CUSTOMER_LEAD_SOURCE:
      return {
        ...state,
        customerLeadSourceList: action.payload,
      }

    case SET_CUSTOMER_LEAD_SOURCE_DETAIL:
      return {
        ...state,
        customerLeadSourceDetail: action.payload,
      }

    case SET_CREATE_CUSTOMER_LEAD_SOURCE:
      return {
        ...state,
        customerLeadSourceList: {
          count: state.customerLeadSourceList.count + 1,
          rows: [action.payload, ...state.customerLeadSourceList.rows],
        },
      }

    case SET_UPDATE_CUSTOMER_LEAD_SOURCE:
      return {
        ...state,
        customerLeadSourceList: {
          count: state.customerLeadSourceList.count,
          rows: state.customerLeadSourceList.rows.map((leadSource) =>
            leadSource.LeadSourceId === action.payload.LeadSourceId
              ? action.payload
              : leadSource
          ),
        },
      }

    case SET_DELETE_CUSTOMER_LEAD_SOURCE:
      return {
        ...state,
        customerLeadSourceList: {
          count: state.customerLeadSourceList.count,
          rows: state.customerLeadSourceList.rows.filter(
            (leadSource) =>
              leadSource.LeadSourceId !== action.payload.LeadSourceId
          ),
        },
      }

    default:
      return state
  }
}

export default customerLeadSource
