import fetch from "auth/APIFetchInterceptor"

const CustomerActivityService = {}

CustomerActivityService.getCustomerActivityList = function (data) {
  return fetch({
    url: "/customer/activities/find-all",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      Rows: data.rows ?? 10,
      Page: data.page ?? 1,
      Search: JSON.stringify(data.queries ?? "{}"),
      Sort: data.sort ?? "ASC",
    },
  })
}

CustomerActivityService.createCustomerActivity = function (data) {
  return fetch({
    url: "/customer/activities/add",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

CustomerActivityService.updateCustomerActivity = function (data) {
  return fetch({
    url: `/customer/activities/update`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { ...data, ActivityId: data?.ActivityId },
  })
}

CustomerActivityService.deleteCustomerActivity = function (data) {
  return fetch({
    url: `/customer/activities/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { ActivityId: data?.ActivityId },
  })
}

export default CustomerActivityService
