import { all, takeEvery, put, fork, call, takeLatest } from "redux-saga/effects"
import {
  AUTH_TOKEN,
  COMMON_SYSTEM_SIGNIN,
  COMMON_TOKEN,
  CUSTOMER_SIGNIN,
  CUSTOMER_TOKEN,
  GET_ROLES_PRIVILEGES,
  PRIVILEGES,
  ROLES,
  SIGNIN,
  SSO_SIGNIN,
  SIGNOUT,
  SYSTEM_SIGNIN,
  CHANGE_SSO_PASSWORD,
  USER_SSO_TOKEN,
  REQUEST_RESET_PASSWORD,
  GET_LOGIN_ACTIVITIES,
  CREATE_LOGIN_ACTIVITY,
  LOGIN_DATE_TIME,
  UNIQUE_SESSION_ID,
  CLIENT_IP_ADDRESS,
  REFRESH_TOKEN,
} from "../constants/Auth"
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  customerAuthenticated,
  systemAuthenticated,
  setRolesPrivileges,
  setAuthLoading,
  setLoginActivities,
} from "../actions/Auth"

import FirebaseService from "services/FirebaseService"
import AuthService from "services/AuthService"
import { env } from "configs/EnvironmentConfig"
import setNotification from "components/shared-components/Notification"
import Utils from "utils"

export function* signIn() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    try {
      const sessionId = Utils.generateUniqueSessionId()

      const oneTimeCodeResult = yield call(AuthService.getOneTimeCode, payload)

      let result = yield call(AuthService.login, {
        code: oneTimeCodeResult?.data?.data?.code,
      })

      if (result.data?.activeSession) {
        const shouldProceed = window.confirm(
          "An active session is detected. Do you want to proceed and log out the other session?"
        )

        if (shouldProceed) {
          const oneMoreTimeCodeResult = yield call(
            AuthService.getOneTimeCode,
            payload
          )

          // Call the force logout API if the user confirms
          result = yield call(AuthService.forceUserLogout, {
            code: oneMoreTimeCodeResult?.data?.data?.code,
          })
        } else {
          return
        }
      }

      sessionStorage.setItem(
        "userinfo",
        JSON.stringify(result?.data?.user_info)
      )

      const ssoToken = yield call(AuthService.userSsoLogin, payload)

      sessionStorage.setItem(USER_SSO_TOKEN, ssoToken.data?.data?.access_token)

      if (!result?.data?.access_token) {
        yield put(showAuthMessage(result?.data?.message))
      } else {
        sessionStorage.setItem(AUTH_TOKEN, result?.data?.access_token)
        sessionStorage.setItem(REFRESH_TOKEN, result?.data?.refresh_token)
        sessionStorage.setItem(LOGIN_DATE_TIME, new Date().toISOString())
        sessionStorage.setItem(UNIQUE_SESSION_ID, sessionId)

        yield call(AuthService.createLoginActivity, {
          ObjectType: "UserId",
          ObjectId: String(result.data?.user_info?.id),
          SessionId: sessionId,
          ActivityDateTime: new Date(),
          ActivityDescription: "Login",
        })

        yield put(authenticated(result?.data))
      }
    } catch (err) {
      let errMessage = err?.response?.data?.message
      yield put(showAuthMessage(errMessage))
    }
  })
}

export function* ssoSignIn() {
  yield takeEvery(SSO_SIGNIN, function* ({ payload }) {
    try {
      const sessionId = Utils.generateUniqueSessionId()

      const result = yield call(AuthService.login, {
        code: payload,
      })
      sessionStorage.setItem(
        "userinfo",
        JSON.stringify(result?.data?.user_info)
      )

      if (!result?.data?.access_token) {
        yield put(showAuthMessage(result?.data?.message))
      } else {
        sessionStorage.setItem(AUTH_TOKEN, result?.data?.access_token)
        sessionStorage.setItem(REFRESH_TOKEN, result?.data?.refresh_token)
        sessionStorage.setItem(LOGIN_DATE_TIME, new Date().toISOString())
        sessionStorage.setItem(UNIQUE_SESSION_ID, sessionId)
        sessionStorage.setItem(CLIENT_IP_ADDRESS, "1.1.1.1")

        yield call(AuthService.createLoginActivity, {
          SessionId: sessionId,
          ObjectType: "UserId",
          ObjectId: String(result.data?.user_info?.id),
          ActivityDateTime: new Date(),
          ActivityDescription: "Login",
        })

        yield put(authenticated(result?.data))
      }
    } catch (err) {
      let errMessage = err?.response?.data?.message
      yield put(showAuthMessage(errMessage))
    }
  })
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      const signOutUser = yield call(FirebaseService.signOutRequest)
      if (signOutUser === undefined) {
        localStorage.removeItem(AUTH_TOKEN)
        sessionStorage.removeItem(AUTH_TOKEN)
        sessionStorage.removeItem(REFRESH_TOKEN)
        sessionStorage.removeItem("userinfo")
        sessionStorage.removeItem(USER_SSO_TOKEN)
        yield put(signOutSuccess(signOutUser))
      } else {
        yield put(showAuthMessage(signOutUser.message))
      }
    } catch (err) {
      yield put(showAuthMessage(err))
    }
  })
}

export function* customerSignIn() {
  yield takeEvery(CUSTOMER_SIGNIN, function* ({ payload }) {
    try {
      const { data } = yield call(AuthService.customerLogin, payload)

      if (!data?.access_token) {
        yield put(showAuthMessage(data?.message))
      } else {
        sessionStorage.setItem(CUSTOMER_TOKEN, data?.access_token)
        yield put(
          customerAuthenticated({ ...data, customer_id: payload.username })
        )
      }
    } catch (err) {
      let errMessage = err?.response?.data?.message
      yield put(showAuthMessage(errMessage))
    }
  })
}

export function* systemSignInAPI() {
  yield takeEvery(SYSTEM_SIGNIN, function* () {
    try {
      const oneTimeCodeResult = yield call(AuthService.getOneTimeCode, {
        email: env.SYSTEM_EMAIL,
        password: env.SYSTEM_PASSWORD,
      })

      const { data } = yield call(AuthService.login, {
        code: oneTimeCodeResult?.data?.data?.code,
      })

      sessionStorage.setItem(AUTH_TOKEN, data?.access_token)
      sessionStorage.setItem(REFRESH_TOKEN, data?.refresh_token)
      yield put(systemAuthenticated(data))
    } catch (err) {
      let errMessage = err?.response?.data?.message
      yield put(showAuthMessage(errMessage))
    }
  })
}

export function* commonSystemSignInAPI() {
  yield takeEvery(COMMON_SYSTEM_SIGNIN, function* () {
    try {
      const oneTimeCodeResult = yield call(AuthService.getOneTimeCodeCommon, {
        email: env.SYSTEM_EMAIL,
        password: env.SYSTEM_PASSWORD,
      })

      const { data } = yield call(AuthService.commonLogin, {
        code: oneTimeCodeResult?.data?.data?.code,
      })

      sessionStorage.setItem(COMMON_TOKEN, data?.access_token)
    } catch (err) {
      let errMessage = err?.response?.data?.message
      yield put(showAuthMessage(errMessage))
    }
  })
}

export function* changeSsoPassword() {
  yield takeEvery(CHANGE_SSO_PASSWORD, function* ({ payload }) {
    try {
      const { data } = yield call(AuthService.changeSsoPassword, payload)

      setNotification({
        type: "success",
        message: data?.message ?? "Change Password Success",
      })

      payload.callback()
    } catch (err) {
      let errMessage = err?.response?.data?.message
      yield put(showAuthMessage(errMessage))
    }
  })
}

export function* fetchRolesPrivileges() {
  yield takeLatest(GET_ROLES_PRIVILEGES, function* () {
    try {
      const { data } = yield call(AuthService.fetchRolesPrivileges)
      sessionStorage.setItem(ROLES, JSON.stringify(data.roles))
      sessionStorage.setItem(PRIVILEGES, JSON.stringify(data.privileges))
      yield put(
        setRolesPrivileges({ roles: data.roles, privileges: data.privileges })
      )
    } catch (error) {
      setNotification({
        type: "error",
        message: "Fetch Roles & Privileges Failed",
        description: "An error occured.",
      })
    }
  })
}

export function* requestResetPassword() {
  yield takeLatest(REQUEST_RESET_PASSWORD, function* ({ payload }) {
    try {
      yield put(setAuthLoading(true))
      const { data } = yield call(AuthService.requestResetPassword, payload)

      if (data.message) {
        setNotification({
          type: "success",
          message: "Request reset password has been sent.",
        })
      }

      yield put(setAuthLoading(false))

      // Closing request reset password modal
      payload.callback()
    } catch (error) {
      yield put(setAuthLoading(false))
      setNotification({
        type: "error",
        message: "Request Reset Password Failed",
        description: "An error occured.",
      })
    }
  })
}

export function* fetchLoginActivitiesAPI() {
  yield takeLatest(GET_LOGIN_ACTIVITIES, function* ({ payload }) {
    try {
      yield put(setAuthLoading(true))

      const { data } = yield call(AuthService.fetchLoginActivities, payload)

      yield put(setLoginActivities(data))

      // payload.callback()
    } catch (error) {
      yield put(setAuthLoading(false))
      setNotification({
        type: "error",
        message: "Fetch Login Activities Failed",
        description: "An error occured.",
      })
    }
  })
}

export function* createLoginActivityAPI() {
  yield takeLatest(
    CREATE_LOGIN_ACTIVITY,
    function* ({ payload, isLogoutActivity }) {
      try {
        const sessionId = sessionStorage.getItem(UNIQUE_SESSION_ID)
        const userIP = sessionStorage.getItem(CLIENT_IP_ADDRESS)
        const loginDateTime = sessionStorage.getItem(LOGIN_DATE_TIME)

        let userInfo = JSON.parse(sessionStorage.getItem("userinfo"))

        yield call(AuthService.createLoginActivity, {
          SessionId: sessionId,
          SourceIP: userIP,
          ObjectType: "UserId",
          ObjectId: String(userInfo?.id),
          ActivityDateTime: new Date(),
          ActivityDescription: payload,
        })

        if (isLogoutActivity) {
          const { data } = yield call(AuthService.fetchLoginActivities, {
            ObjectType: "UserId",
            ObjectId: userInfo?.id,
            ActivityDateTime: loginDateTime,
          })

          yield call(AuthService.logout)

          Utils.logout()

          yield put(setLoginActivities(data))
        }
      } catch (error) {
        setNotification({
          type: "error",
          message: "Create Logout History Failed",
          description: "An error occured.",
        })
      }
    }
  )
}

export default function* rootSaga() {
  yield all([
    fork(signIn),
    fork(ssoSignIn),
    fork(signOut),
    fork(customerSignIn),
    fork(systemSignInAPI),
    fork(commonSystemSignInAPI),
    fork(changeSsoPassword),
    fork(fetchRolesPrivileges),
    fork(requestResetPassword),
    fork(fetchLoginActivitiesAPI),
    fork(createLoginActivityAPI),
  ])
}
