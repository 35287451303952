import {
  SET_IS_REPORTING_LOADING,
  GET_ALL_MEMBER_LIST,
  GET_MEMBER_LIST,
  SET_MEMBER_LIST,
  GET_CUSTOMER_ANALYSIS_REPORT,
  SET_CUSTOMER_ANALYSIS_REPORT,
  GET_CUSTOMER_REWARD_STATUS_REPORT,
  SET_CUSTOMER_REWARD_STATUS_REPORT,
  SET_CUSTOMER_PERFORMANCE_REPORT,
  GET_CUSTOMER_PERFORMANCE_REPORT,
  GET_MEMBER_LIST_PREMIUM,
  SET_MEMBER_LIST_PREMIUM,
  SET_PRODUCT_SALES_ANALYSIS_REPORT,
  GET_PRODUCT_SALES_ANALYSIS_REPORT,
  SET_MEMBER_SALES_ANALYSIS_REPORT,
  GET_MEMBER_SALES_ANALYSIS_REPORT,
  SET_MEMBER_PROFILE_AMENDMENT_REPORT,
  GET_MEMBER_PROFILE_AMENDMENT_REPORT,
  SET_CUSTOMER_TRANSACTION_REPORT,
  GET_CUSTOMER_TRANSACTION_REPORT,
  SET_NON_MEMBER_TRANSACTION_REPORT,
  GET_NON_MEMBER_TRANSACTION_REPORT,
  SET_REPEAT_CUSTOMER_ANALYSIS_REPORT,
  GET_REPEAT_CUSTOMER_ANALYSIS_REPORT,
  GET_PREMIUM_GIFT_REDEMPTION_LISTING,
  SET_PREMIUM_GIFT_REDEMPTION_LISTING,
  GET_INACTIVE_REACTIVE_MEMBER_LISTING,
  SET_INACTIVE_REACTIVE_MEMBER_LISTING,
  GET_MEMBER_CATEGORY_ANALYSIS_REPORT,
  SET_MEMBER_CATEGORY_ANALYSIS_REPORT,
  GET_AUDIT_TRAIL_REPORT,
  SET_AUDIT_TRAIL_REPORT,
  GET_FEEDBACK_ANALYSIS_REPORT,
  SET_FEEDBACK_ANALYSIS_REPORT,
  GET_CAMPAIGN_TARGET_LIST_REPORT,
  SET_CAMPAIGN_TARGET_LIST_REPORT,
} from "redux/constants/Reporting"

export const setIsReportingLoading = (payload) => {
  return {
    type: SET_IS_REPORTING_LOADING,
    payload,
  }
}

export const getAllMemberList = (data) => {
  return {
    type: GET_ALL_MEMBER_LIST,
    payload: data,
  }
}

export const getMemberList = (
  data,
  isGeneratingReport = false,
  isGeneratingExcelReport = false
) => {
  return {
    type: GET_MEMBER_LIST,
    payload: data,
    isGeneratingReport,
    isGeneratingExcelReport,
  }
}

export const getMemberListPremium = (
  data,
  isGeneratingReport = false,
  isFeedbackFetch = false,
  isGeneratingExcelReport = false
) => {
  return {
    type: GET_MEMBER_LIST_PREMIUM,
    payload: data,
    isGeneratingReport,
    isFeedbackFetch,
    isGeneratingExcelReport,
  }
}

export const setMemberList = (data) => {
  return {
    type: SET_MEMBER_LIST,
    payload: data,
  }
}

export const setMemberListPremium = (data) => {
  return {
    type: SET_MEMBER_LIST_PREMIUM,
    payload: data,
  }
}

export const getCustomerAnalysisReport = (data) => {
  return {
    type: GET_CUSTOMER_ANALYSIS_REPORT,
    payload: data,
  }
}

export const setCustomerAnalysisReport = (data) => {
  return {
    type: SET_CUSTOMER_ANALYSIS_REPORT,
    payload: data,
  }
}

export const setCustomerRewardStatusReport = (data) => {
  return {
    type: SET_CUSTOMER_REWARD_STATUS_REPORT,
    payload: data,
  }
}

export const getCustomerRewardStatusReport = (data) => {
  return {
    type: GET_CUSTOMER_REWARD_STATUS_REPORT,
    payload: data,
  }
}

export const setCustomerPerformanceReport = (data) => {
  return {
    type: SET_CUSTOMER_PERFORMANCE_REPORT,
    payload: data,
  }
}

export const getCustomerPerformanceReport = (data) => {
  return {
    type: GET_CUSTOMER_PERFORMANCE_REPORT,
    payload: data,
  }
}

export const setProductSalesAnalysisReport = (data) => {
  return {
    type: SET_PRODUCT_SALES_ANALYSIS_REPORT,
    payload: data,
  }
}

export const getProductSalesAnalysisReport = (data) => {
  return {
    type: GET_PRODUCT_SALES_ANALYSIS_REPORT,
    payload: data,
  }
}

export const setMemberSalesAnalysisReport = (data) => {
  return {
    type: SET_MEMBER_SALES_ANALYSIS_REPORT,
    payload: data,
  }
}

export const getMemberSalesAnalysisReport = (data) => {
  return {
    type: GET_MEMBER_SALES_ANALYSIS_REPORT,
    payload: data,
  }
}

export const setMemberProfileAmendmentReport = (data) => {
  return {
    type: SET_MEMBER_PROFILE_AMENDMENT_REPORT,
    payload: data,
  }
}

export const getMemberProfileAmendmentReport = (data) => {
  return {
    type: GET_MEMBER_PROFILE_AMENDMENT_REPORT,
    payload: data,
  }
}

export const setCustomerTransactionReport = (data) => {
  return {
    type: SET_CUSTOMER_TRANSACTION_REPORT,
    payload: data,
  }
}

export const getCustomerTransactionReport = (data) => {
  return {
    type: GET_CUSTOMER_TRANSACTION_REPORT,
    payload: data,
  }
}

export const setNonMemberTransactionReport = (data) => {
  return {
    type: SET_NON_MEMBER_TRANSACTION_REPORT,
    payload: data,
  }
}

export const getNonMemberTransactionReport = (data) => {
  return {
    type: GET_NON_MEMBER_TRANSACTION_REPORT,
    payload: data,
  }
}

export const setRepeatCustomerAnalysisReport = (data) => {
  return {
    type: SET_REPEAT_CUSTOMER_ANALYSIS_REPORT,
    payload: data,
  }
}

export const getRepeatCustomerAnalysisReport = (data) => {
  return {
    type: GET_REPEAT_CUSTOMER_ANALYSIS_REPORT,
    payload: data,
  }
}

export const setPremiumGiftRedemptionListing = (data) => {
  return {
    type: SET_PREMIUM_GIFT_REDEMPTION_LISTING,
    payload: data,
  }
}

export const getPremiumGiftRedemptionListing = (data) => {
  return {
    type: GET_PREMIUM_GIFT_REDEMPTION_LISTING,
    payload: data,
  }
}

export const setInactiveReactiveMemberListing = (data) => {
  return {
    type: SET_INACTIVE_REACTIVE_MEMBER_LISTING,
    payload: data,
  }
}

export const getInactiveReactiveMemberListing = (
  data,
  isGeneratingExcelReport = false
) => {
  return {
    type: GET_INACTIVE_REACTIVE_MEMBER_LISTING,
    payload: data,
    isGeneratingExcelReport,
  }
}

export const setMemberCategoryAnalysisReport = (data) => {
  return {
    type: SET_MEMBER_CATEGORY_ANALYSIS_REPORT,
    payload: data,
  }
}

export const getMemberCategoryAnalysisReport = (data) => {
  return {
    type: GET_MEMBER_CATEGORY_ANALYSIS_REPORT,
    payload: data,
  }
}

export const setAuditTrailReport = (data) => {
  return {
    type: SET_AUDIT_TRAIL_REPORT,
    payload: data,
  }
}

export const getAuditTrailReport = (data) => {
  return {
    type: GET_AUDIT_TRAIL_REPORT,
    payload: data,
  }
}

export const setFeedbackAnalysisReport = (data) => {
  return {
    type: SET_FEEDBACK_ANALYSIS_REPORT,
    payload: data,
  }
}

export const getFeedbackAnalysisReport = (data) => {
  return {
    type: GET_FEEDBACK_ANALYSIS_REPORT,
    payload: data,
  }
}

export const setCampaignTargetListReport = (data) => {
  return {
    type: SET_CAMPAIGN_TARGET_LIST_REPORT,
    payload: data,
  }
}

export const getCampaignTargetListReport = (data) => {
  return {
    type: GET_CAMPAIGN_TARGET_LIST_REPORT,
    payload: data,
  }
}
