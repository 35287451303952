import {
  CREATE_CUSTOMER_LEAD_SOURCE,
  DELETE_CUSTOMER_LEAD_SOURCE,
  GET_LIST_CUSTOMER_LEAD_SOURCE,
  SET_CREATE_CUSTOMER_LEAD_SOURCE,
  SET_DELETE_CUSTOMER_LEAD_SOURCE,
  SET_LIST_CUSTOMER_LEAD_SOURCE,
  UPDATE_CUSTOMER_LEAD_SOURCE,
  SET_UPDATE_CUSTOMER_LEAD_SOURCE,
  GET_CUSTOMER_LEAD_SOURCE_DETAIL,
  SET_CUSTOMER_LEAD_SOURCE_DETAIL,
} from "redux/constants/CustomerLeadSource"

export const createCustomerLeadSource = (data) => {
  return {
    type: CREATE_CUSTOMER_LEAD_SOURCE,
    payload: data,
  }
}

export const setCreateCustomerLeadSource = (data) => {
  return {
    type: SET_CREATE_CUSTOMER_LEAD_SOURCE,
    payload: data,
  }
}

export const getListCustomerLeadSource = (data) => {
  return {
    type: GET_LIST_CUSTOMER_LEAD_SOURCE,
    payload: data,
  }
}

export const setListCustomerLeadSource = (data) => {
  return {
    type: SET_LIST_CUSTOMER_LEAD_SOURCE,
    payload: data,
  }
}

export const updateCustomerLeadSource = (data) => {
  return {
    type: UPDATE_CUSTOMER_LEAD_SOURCE,
    payload: data,
  }
}

export const setUpdateCustomerLeadSource = (data) => {
  return {
    type: SET_UPDATE_CUSTOMER_LEAD_SOURCE,
    payload: data,
  }
}

export const deleteCustomerLeadSource = (data) => {
  return {
    type: DELETE_CUSTOMER_LEAD_SOURCE,
    payload: data,
  }
}

export const setDeleteCustomerLeadSource = (data) => {
  return {
    type: SET_DELETE_CUSTOMER_LEAD_SOURCE,
    payload: data,
  }
}

export const getCustomerLeadSourceDetail = (data) => {
  return {
    type: GET_CUSTOMER_LEAD_SOURCE_DETAIL,
    payload: data,
  }
}

export const setCustomerLeadSourceDetail = (data) => {
  return {
    type: SET_CUSTOMER_LEAD_SOURCE_DETAIL,
    payload: data,
  }
}
