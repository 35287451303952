import fetch from "auth/APIFetchInterceptor"

const CustomerLeadSourceService = {}

CustomerLeadSourceService.createCustomerLeadSource = function (data) {
  return fetch({
    url: `/customer/lead-sources/add`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

CustomerLeadSourceService.getListCustomerLeadSource = function (data) {
  const searchQuery = {}
  if (!(data.Code === "")) {
    searchQuery.Code = data.Code?.toUpperCase()
  }
  if (!(data.Name === "")) {
    searchQuery.Name = data.Name
  }
  if (!(data.Type === "")) {
    searchQuery.Type = data.Type
  }
  if (!(data.Status === "" || data.Status === "All")) {
    searchQuery.Status = data.Status
  }

  return fetch({
    url: `/customer/lead-sources/find-all`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      Rows: data.rows ? data.rows : 1000,
      Page: data.page ? data.page : 1,
      Search: JSON.stringify(searchQuery ?? {}),
    },
  })
}

CustomerLeadSourceService.getOneCustomerLeadSource = function (data) {
  return fetch({
    url: "/customer/lead-sources/find-one",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      LeadSourceId: data?.LeadSourceId,
    },
  })
}

CustomerLeadSourceService.updateCustomerLeadSource = function (data) {
  return fetch({
    url: `/customer/lead-sources/update`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

CustomerLeadSourceService.deleteCustomerLeadSource = function (data) {
  return fetch({
    url: `/customer/lead-sources/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      LeadSourceId: data?.LeadSourceId,
    },
  })
}

export default CustomerLeadSourceService
