import fetch from "auth/APIFetchInterceptor"

const CustomerAddressService = {}

CustomerAddressService.createCustomerAddress = function (data) {
  return fetch({
    url: "/customer/addresses/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: data,
  })
}

CustomerAddressService.getListCustomerAddress = function (data) {
  const searchQuery = {}

  if (!(data.CustomerId === "")) {
    searchQuery.CustomerId = data.CustomerId
  }

  return fetch({
    url: "/customer/addresses/find-all",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: {
      Rows: data.rows ? data.rows : 1000,
      Page: data.page ? data.page : 1,
      Search: JSON.stringify(searchQuery ?? {}),
    },
    method: "post",
  })
}

CustomerAddressService.getCustomerAddress = function (data) {
  return fetch({
    url: "/customer/addresses/find-one",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      AddressId: data?.AddressId,
    },
  })
}

CustomerAddressService.updateCustomerAddress = function (data) {
  return fetch({
    url: `/customer/addresses/update`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data,
  })
}

CustomerAddressService.deleteCustomerAddress = function (data) {
  return fetch({
    url: `/customer/addresses/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: {
      AddressId: data?.AddressId,
    },
  })
}

export default CustomerAddressService
