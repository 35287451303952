import {
  RESULT_CAMPAIGN_TARGET,
  SET_MEMBER,
  SET_CAMPAIGN_TARGET,
} from "redux/constants/CampaignTarget"

const initialState = {
  campaignTargetList: [],
  member: [],
}

const campaignTarget = (state = initialState, action) => {
  switch (action.type) {
    case RESULT_CAMPAIGN_TARGET:
      if (action.payload.type === "LIST") {
        return {
          ...state,
          campaignTargetList: action.payload.data,
        }
      } else if (action.payload.type === "MEMBER LIST") {
        if (action.payload?.data?.rows[0]) {
          return {
            ...state,
            member: [...state.member, action.payload.data?.rows[0]],
          }
        } else {
          return state
        }
      } else if (action.payload.type === "DELETE") {
        return {
          ...state,
          campaignTargetList: {
            ...state.campaignTargetList,
            rows: state.campaignTargetList?.rows?.filter(
              (el) => el?.TargetListId !== action.payload.TargetListId
            ),
          },
        }
      }
      break
    case SET_MEMBER:
      return {
        ...state,
        member: action.payload,
      }
    case SET_CAMPAIGN_TARGET:
      return {
        ...state,
        campaignTargetList: action.payload,
      }
    default:
      return state
  }
}

export default campaignTarget
