export const CREATE_CUSTOMER_LEAD_SOURCE = "CREATE_CUSTOMER_LEAD_SOURCE"
export const SET_CREATE_CUSTOMER_LEAD_SOURCE = "SET_CREATE_CUSTOMER_LEAD_SOURCE"

export const GET_LIST_CUSTOMER_LEAD_SOURCE = "GET_LIST_CUSTOMER_LEAD_SOURCE"
export const SET_LIST_CUSTOMER_LEAD_SOURCE = "SET_LIST_CUSTOMER_LEAD_SOURCE"

export const GET_CUSTOMER_LEAD_SOURCE_DETAIL = "GET_CUSTOMER_LEAD_SOURCE_DETAIL"
export const SET_CUSTOMER_LEAD_SOURCE_DETAIL = "SET_CUSTOMER_LEAD_SOURCE_DETAIL"

export const UPDATE_CUSTOMER_LEAD_SOURCE = "UPDATE_CUSTOMER_LEAD_SOURCE"
export const SET_UPDATE_CUSTOMER_LEAD_SOURCE = "SET_UPDATE_CUSTOMER_LEAD_SOURCE"

export const DELETE_CUSTOMER_LEAD_SOURCE = "DELETE_CUSTOMER_LEAD_SOURCE"
export const SET_DELETE_CUSTOMER_LEAD_SOURCE = "SET_DELETE_CUSTOMER_LEAD_SOURCE"
