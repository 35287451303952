import { AUTH_TOKEN } from "redux/constants/Auth"
import fetch from "auth/APIFetchInterceptor"

const MembershipsService = {}

MembershipsService.createMemberships = function (data) {
  return fetch({
    url: "/customer/memberships/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: data,
  })
}

MembershipsService.getListMemberships = function (data) {
  let searchquery = { ...data }
  if (
    !(data.Status === "" || data.Status === "All" || data.Status === undefined)
  )
    searchquery.Status = data.Status
  if (!(data.Name === "" || data.Name === "All" || data.Name === undefined))
    searchquery.Name = data.Name
  if (data.CustomerId) searchquery.MembershipNo = data.CustomerId
  delete searchquery.rows
  delete searchquery.page
  delete searchquery.access_token
  delete searchquery.callback
  delete searchquery.CustomerId
  return fetch({
    url: `/customer/memberships/find-all`,
    data: {
      Rows: data.rows ? data.rows : 10,
      Page: data.page ? data.page : 1,
      Search: JSON.stringify(searchquery ?? {}),
    },
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
  })
}

const cleanSearch = (search) => {
  let cleanSearchObj = { ...search }
  Object.keys(search).forEach((key) => {
    if (
      search[key] === undefined ||
      search[key] === null ||
      search[key]?.trim() === ""
    )
      delete cleanSearchObj[key]
  })
  return cleanSearchObj
}

const getSum = (data) => {
  return data.reduce((a, b) => a + b, 0)
}

MembershipsService.getListMemberPointStatement = async function (data) {
  const { rows, page, search } = data
  let { MembershipNo } = search || {}
  let customerMemberships = []
  let pointAdjustments = []
  let customerMemberBalances = []

  try {
    customerMemberships = await fetch({
      url: `/customer/memberships/find-all`,
      method: "post",
      data: {
        Rows: rows ?? 10,
        Page: page ?? 1,
        Search: JSON.stringify(cleanSearch(search) ?? {}),
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
      },
    })

    if (!customerMemberships?.data?.rows[0]?.MembershipNo) {
      return {
        alert: {
          success: false,
          message: "Record not found",
        },
      }
    }

    MembershipNo =
      search.MembershipNo ??
      customerMemberships?.data?.rows[0]?.MembershipNo ??
      null

    pointAdjustments = await fetch({
      url: `/point/adjustments/find-all`,
      method: "post",
      data: {
        Rows: rows ?? 10,
        Page: page ?? 1,
        Search: JSON.stringify({ MembershipNo }),
        SortOption: "SequenceNo",
        SortDirection: "ASC",
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
      },
    })

    customerMemberBalances = await fetch({
      url: `/customer/memberships/balances`,
      method: "post",
      data: { MembershipNo },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
      },
    })

    pointAdjustments =
      pointAdjustments?.data?.rows?.sort((a, b) =>
        a.SequenceNo > b.SequenceNo ? 1 : -1
      ) || []

    let customerMemberBalance = customerMemberBalances?.data[0] || {}
    customerMemberBalance = {
      ...customerMemberBalance,
      TotalPointsEarned:
        getSum(
          pointAdjustments
            .filter((d) => d.PointAmount > 0)
            .map((d) => d.PointAmount)
        ) - getSum(pointAdjustments.map((d) => d.PointReversed)),
      TotalPointsRedeemed: getSum(pointAdjustments.map((d) => d.PointUtilized)),
      TotalPointsExpired: getSum(pointAdjustments.map((d) => d.PointExpired)),
    }

    return {
      pointAdjustments,
      customerMemberBalance,
      customerMembership: customerMemberships.data?.rows?.[0],
    }
  } catch (e) {
    return {
      alert: {
        success: false,
        message: "Record not found",
      },
    }
  }
}

MembershipsService.getListPointSummaryReport = async function (data) {
  const { search } = data
  let { MembershipNo, IdNo, ContactNo } = search || {}

  return await fetch({
    url: `/reporting/point-summary-report`,
    method: "post",
    data: {
      MembershipNo: MembershipNo ?? "",
      CustomerIDNo: IdNo ? IdNo.toUpperCase() : "",
      ContactNo: ContactNo ?? "",
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

MembershipsService.getMemberships = function (data) {
  return fetch({
    url: `/customer/memberships/find-one`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { mbsno: data.GroupId },
  })
}

MembershipsService.updateMemberships = function (data) {
  return fetch({
    url: `/customer/memberships/update`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: { ...data, mbsno: data.GroupId },
    method: "post",
  })
}

MembershipsService.deleteMemberships = function (data) {
  return fetch({
    url: `/customer/memberships/delete`,
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { mbsno: data.GroupId },
  })
}

export default MembershipsService
