import {
  SET_CREATE_LEAD_SOURCE_PROSPECTS,
  SET_LIST_LEAD_SOURCE_PROSPECTS,
  SET_LEAD_SOURCE_PROSPECTS_DETAIL,
  UNMOUNT_CREATED_LEAD_SOURCE_PROSPECTS,
} from "redux/constants/ImportLeadsProspects"

const initialState = {
  leadSourceProspects: {
    count: 0,
    rows: [],
  },
  leadSourceProspectsDetail: {},
  createdCustomerLeadSourceProspects: [],
}

const importLeadsProspects = (state = initialState, action) => {
  switch (action.type) {
    case SET_LIST_LEAD_SOURCE_PROSPECTS:
      return {
        ...state,
        leadSourceProspects: action.payload,
      }

    case SET_LEAD_SOURCE_PROSPECTS_DETAIL:
      return {
        ...state,
        leadSourceProspectsDetail: action.payload,
      }

    case SET_CREATE_LEAD_SOURCE_PROSPECTS:
      return {
        ...state,
        createdCustomerLeadSourceProspects: [
          ...state.createdCustomerLeadSourceProspects,
          action.payload,
        ],
      }

    case UNMOUNT_CREATED_LEAD_SOURCE_PROSPECTS:
      return {
        ...state,
        createdCustomerLeadSourceProspects: [],
      }
    default:
      return state
  }
}

export default importLeadsProspects
