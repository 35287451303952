export const CREATE_LEAD_SOURCE_PROSPECTS = "CREATE_LEAD_SOURCE_PROSPECTS"
export const SET_CREATE_LEAD_SOURCE_PROSPECTS =
  "SET_CREATE_LEAD_SOURCE_PROSPECTS"
export const UNMOUNT_CREATED_LEAD_SOURCE_PROSPECTS =
  "UNMOUNT_CREATED_LEAD_SOURCE_PROSPECTS"

export const GET_LIST_LEAD_SOURCE_PROSPECTS = "GET_LIST_LEAD_SOURCE_PROSPECTS"
export const SET_LIST_LEAD_SOURCE_PROSPECTS = "SET_LIST_LEAD_SOURCE_PROSPECTS"

export const GET_LEAD_SOURCE_PROSPECTS_DETAIL =
  "GET_LEAD_SOURCE_PROSPECTS_DETAIL"
export const SET_LEAD_SOURCE_PROSPECTS_DETAIL =
  "SET_LEAD_SOURCE_PROSPECTS_DETAIL"
