export const SET_IS_REPORTING_LOADING = "SET_IS_REPORTING_LOADING"

export const GET_ALL_MEMBER_LIST = "GET_ALL_MEMBER_LIST"

export const GET_MEMBER_LIST = "GET_MEMBER_LIST"
export const SET_MEMBER_LIST = "SET_MEMBER_LIST"

export const GET_MEMBER_LIST_PREMIUM = "GET_MEMBER_LIST_PREMIUM"
export const SET_MEMBER_LIST_PREMIUM = "SET_MEMBER_LIST_PREMIUM"

export const GET_CUSTOMER_ANALYSIS_REPORT = "GET_CUSTOMER_ANALYSIS_REPORT"
export const SET_CUSTOMER_ANALYSIS_REPORT = "SET_CUSTOMER_ANALYSIS_REPORT"

export const GET_CUSTOMER_REWARD_STATUS_REPORT =
  "GET_CUSTOMER_REWARD_STATUS_REPORT"
export const SET_CUSTOMER_REWARD_STATUS_REPORT =
  "SET_CUSTOMER_REWARD_STATUS_REPORT"

export const GET_CUSTOMER_PERFORMANCE_REPORT = "GET_CUSTOMER_PERFORMANCE_REPORT"
export const SET_CUSTOMER_PERFORMANCE_REPORT = "SET_CUSTOMER_PERFORMANCE_REPORT"

export const GET_PRODUCT_SALES_ANALYSIS_REPORT =
  "GET_PRODUCT_SALES_ANALYSIS_REPORT"
export const SET_PRODUCT_SALES_ANALYSIS_REPORT =
  "SET_PRODUCT_SALES_ANALYSIS_REPORT"

export const GET_MEMBER_SALES_ANALYSIS_REPORT =
  "GET_MEMBER_SALES_ANALYSIS_REPORT"
export const SET_MEMBER_SALES_ANALYSIS_REPORT =
  "SET_MEMBER_SALES_ANALYSIS_REPORT"

export const GET_MEMBER_PROFILE_AMENDMENT_REPORT =
  "GET_MEMBER_PROFILE_AMENDMENT_REPORT"
export const SET_MEMBER_PROFILE_AMENDMENT_REPORT =
  "SET_MEMBER_PROFILE_AMENDMENT_REPORT"

export const GET_CUSTOMER_TRANSACTION_REPORT = "GET_CUSTOMER_TRANSACTION_REPORT"
export const SET_CUSTOMER_TRANSACTION_REPORT = "SET_CUSTOMER_TRANSACTION_REPORT"

export const GET_NON_MEMBER_TRANSACTION_REPORT =
  "GET_NON_MEMBER_TRANSACTION_REPORT"
export const SET_NON_MEMBER_TRANSACTION_REPORT =
  "SET_NON_MEMBER_TRANSACTION_REPORT"

export const GET_REPEAT_CUSTOMER_ANALYSIS_REPORT =
  "GET_REPEAT_CUSTOMER_ANALYSIS_REPORT"
export const SET_REPEAT_CUSTOMER_ANALYSIS_REPORT =
  "SET_REPEAT_CUSTOMER_ANALYSIS_REPORT"

export const GET_PREMIUM_GIFT_REDEMPTION_LISTING =
  "GET_PREMIUM_GIFT_REDEMPTION_LISTING"
export const SET_PREMIUM_GIFT_REDEMPTION_LISTING =
  "SET_PREMIUM_GIFT_REDEMPTION_LISTING"

export const GET_INACTIVE_REACTIVE_MEMBER_LISTING =
  "GET_INACTIVE_REACTIVE_MEMBER_LISTING"
export const SET_INACTIVE_REACTIVE_MEMBER_LISTING =
  "SET_INACTIVE_REACTIVE_MEMBER_LISTING"

export const GET_MEMBER_CATEGORY_ANALYSIS_REPORT =
  "GET_MEMBER_CATEGORY_ANALYSIS_REPORT"
export const SET_MEMBER_CATEGORY_ANALYSIS_REPORT =
  "SET_MEMBER_CATEGORY_ANALYSIS_REPORT"

export const GET_AUDIT_TRAIL_REPORT = "GET_AUDIT_TRAIL_REPORT"
export const SET_AUDIT_TRAIL_REPORT = "SET_AUDIT_TRAIL_REPORT"

export const GET_FEEDBACK_ANALYSIS_REPORT = "GET_FEEDBACK_ANALYSIS_REPORT"
export const SET_FEEDBACK_ANALYSIS_REPORT = "SET_FEEDBACK_ANALYSIS_REPORT"

export const GET_CAMPAIGN_TARGET_LIST_REPORT = "GET_CAMPAIGN_TARGET_LIST_REPORT"
export const SET_CAMPAIGN_TARGET_LIST_REPORT = "SET_CAMPAIGN_TARGET_LIST_REPORT"
