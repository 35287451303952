import {
  CREATE_LEAD_SOURCE_PROSPECTS,
  SET_CREATE_LEAD_SOURCE_PROSPECTS,
  UNMOUNT_CREATED_LEAD_SOURCE_PROSPECTS,
  GET_LIST_LEAD_SOURCE_PROSPECTS,
  SET_LIST_LEAD_SOURCE_PROSPECTS,
  GET_LEAD_SOURCE_PROSPECTS_DETAIL,
  SET_LEAD_SOURCE_PROSPECTS_DETAIL,
} from "redux/constants/ImportLeadsProspects"

export const createLeadSourceProspects = (data) => {
  return {
    type: CREATE_LEAD_SOURCE_PROSPECTS,
    payload: data,
  }
}

export const setCreateLeadSourceProspects = (data) => {
  return {
    type: SET_CREATE_LEAD_SOURCE_PROSPECTS,
    payload: data,
  }
}

export const unmountCreatedLeadSourceProspects = () => {
  return {
    type: UNMOUNT_CREATED_LEAD_SOURCE_PROSPECTS,
  }
}

export const getListLeadSourceProspects = (data) => {
  return {
    type: GET_LIST_LEAD_SOURCE_PROSPECTS,
    payload: data,
  }
}

export const setListLeadSourceProspects = (data) => {
  return {
    type: SET_LIST_LEAD_SOURCE_PROSPECTS,
    payload: data,
  }
}

export const getLeadSourceProspectsDetail = (data) => {
  return {
    type: GET_LEAD_SOURCE_PROSPECTS_DETAIL,
    payload: data,
  }
}

export const setLeadSourceProspectsDetail = (data) => {
  return {
    type: SET_LEAD_SOURCE_PROSPECTS_DETAIL,
    payload: data,
  }
}
