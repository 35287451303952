import {
  SSO_API_BASE_URL,
  CRM_CLIENT_ID,
  API_BASE_URL,
  COMMON_API_BASE_URL,
  COMMON_CLIENT_ID,
} from "configs/AppConfig"
import axios from "axios"
import { env } from "../configs/EnvironmentConfig"
import {
  AUTH_TOKEN,
  UNIQUE_SESSION_ID,
  USER_SSO_TOKEN,
} from "redux/constants/Auth"

const ssoFetch = axios.create({
  baseURL: SSO_API_BASE_URL,
  timeout: 60000,
})

const fetch = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
  withCredentials: true,
})

const commonFetch = axios.create({
  baseURL: COMMON_API_BASE_URL,
  timeout: 60000,
})

const AuthService = {}

AuthService.getOneTimeCode = function (payload) {
  return ssoFetch({
    url: "/sso/authorization",
    method: "post",
    data: {
      ...payload,
      client_id: CRM_CLIENT_ID,
    },
  })
}

AuthService.getOneTimeCodeCommon = function (payload) {
  return ssoFetch({
    url: "/sso/authorization",
    method: "post",
    data: {
      ...payload,
      client_id: COMMON_CLIENT_ID,
    },
  })
}

AuthService.ssoLogin = function () {
  return ssoFetch({
    url: "/auth/login",
    method: "post",
    data: {
      email: env.SYSTEM_EMAIL,
      password: env.SYSTEM_PASSWORD,
    },
  })
}

AuthService.userSsoLogin = function (data) {
  return ssoFetch({
    url: "/auth/login",
    method: "post",
    data: {
      email: data.email,
      password: data.password,
    },
  })
}

AuthService.changeSsoPassword = async function (data) {
  let accessToken = sessionStorage.getItem(USER_SSO_TOKEN)
  return ssoFetch({
    url: "/profile/change-password",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    method: "post",
    data,
  })
}

AuthService.login = function (payload) {
  return fetch({
    url: "/auth/login",
    method: "post",
    data: payload,
  })
}

AuthService.forceUserLogout = function (payload) {
  return fetch({
    url: "/auth/force-logout",
    method: "post",
    data: payload,
  })
}

AuthService.logout = function (payload) {
  return fetch({
    url: "/auth/logout",
    method: "post",
  })
}

AuthService.commonLogin = function (payload) {
  return commonFetch({
    url: "/auth/login",
    method: "post",
    data: payload,
  })
}

AuthService.customerLogin = function (payload) {
  return fetch({
    url: "auth/customer/login",
    method: "post",
    data: payload,
  })
}

AuthService.fetchRolesPrivileges = function () {
  let accessToken = sessionStorage.getItem(AUTH_TOKEN)
  return fetch({
    headers: { Authorization: `Bearer ${accessToken}` },
    url: "/roles-privileges",
    method: "get",
  })
}

AuthService.requestResetPassword = async function (data) {
  return ssoFetch({
    url: "/auth/request-reset-password",
    method: "post",
    data,
  })
}

AuthService.createLoginActivity = function (data) {
  let accessToken = sessionStorage.getItem(AUTH_TOKEN)

  return fetch({
    url: "/login-activity/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data,
  })
}

AuthService.fetchLoginActivities = function (data) {
  let accessToken = sessionStorage.getItem(AUTH_TOKEN)
  const sessionId = sessionStorage.getItem(UNIQUE_SESSION_ID)

  const searchQuery = {
    SessionId: sessionId,
  }

  if (!(data.ObjectType === "")) {
    searchQuery.ObjectType = data.ObjectType
  }
  if (!(data.ObjectId === "")) {
    searchQuery.ObjectId = data.ObjectId
  }
  if (data.ActivityDateTime) {
    searchQuery.ActivityDateTimeFrom = data.ActivityDateTime
  }

  return fetch({
    url: "/login-activity/find-all",
    method: "post",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      Rows: data.rows ? data.rows : 1000,
      Page: data.page ? data.page : 1,
      Search: JSON.stringify(searchQuery ?? {}),
    },
  })
}

export default AuthService
