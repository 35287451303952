import fetch from "auth/APIFetchInterceptor"

const CustomerTaskService = {}

CustomerTaskService.createCustomerTask = function (data) {
  return fetch({
    url: "/task/tasks/add",
    method: "post",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    data: data,
  })
}

CustomerTaskService.getListCustomerTask = function (data) {
  const payload = {
    Rows: data.rows,
    Page: data.pages,
    Search: JSON.stringify(data.queries),
  }

  return fetch({
    url: "/task/tasks/find-all",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: payload,
  })
}

CustomerTaskService.getCustomerTask = function (data) {
  const payload = {
    TaskId: data.id,
  }

  return fetch({
    url: "/task/tasks/find-one",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: payload,
  })
}

CustomerTaskService.updateCustomerTask = function (data) {
  const payload = {
    ...data,
    TaskId: data.TaskId,
  }

  if (payload.access_token) delete payload.access_token

  return fetch({
    url: "/task/tasks/update",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: payload,
  })
}

CustomerTaskService.updateCustomerTaskAndProgressLogs = async function (data) {
  const { updatedCustomerTask, updatedTaskProgressLogs, access_token } = data
  const { TaskId } = updatedCustomerTask

  const customerTaskResponse = await fetch({
    url: "/task/tasks/update",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    data: { ...updatedCustomerTask, TaskId },
    method: "post",
  })

  if (customerTaskResponse) {
    updatedTaskProgressLogs.forEach(async (log) => {
      await fetch({
        url: "/task/progress-logs/update",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        data: { ...log, ProgressLogId: log.ProgressLogId },
        method: "post",
      })
    })
  }

  return customerTaskResponse
}

CustomerTaskService.deleteCustomerTask = function (data) {
  return fetch({
    url: "/task/tasks/delete",
    headers: {
      Authorization: `Bearer ${data.access_token}`,
    },
    method: "post",
    data: { TaskId: data.id },
  })
}

export default CustomerTaskService
