import setNotification from "components/shared-components/Notification"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import {
  setSendEmailLoading,
  setValidateOtpLoading,
  setUpdateEmailLoading,
  setValidateCustomerEmailLoading,
} from "redux/actions/CustomerEmailVerification"
import {
  CHANGE_OTP_EMAIL,
  SEND_EMAIL_VERIFICATION,
  VALIDATE_OTP_CODE,
  VALIDATE_CUSTOMER_EMAIL,
} from "redux/constants/CustomerEmailVerification"
import CustomerEmailService from "services/CustomerEmailService"

export function* sendEmailVerificationAPI() {
  yield takeEvery(SEND_EMAIL_VERIFICATION, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token

    yield put(setSendEmailLoading(true))

    try {
      const { data } = yield call(
        CustomerEmailService.sendEmailVerificationCode,
        payload
      )

      setNotification({
        type: "success",
        message: data.message || "Success Send Email",
      })
    } catch (err) {
      if (err?.response?.data) {
        const { message, error } = err.response.data
        setNotification({
          type: "error",
          message: error,
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: err.message,
        })
      }
    } finally {
      yield put(setSendEmailLoading(false))
    }
  })
}

export function* validateOtpCodeAPI() {
  yield takeEvery(VALIDATE_OTP_CODE, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token

    yield put(setValidateOtpLoading(true))

    try {
      yield call(CustomerEmailService.validateOtpCode, payload)

      yield call(CustomerEmailService.updateEmailSetting, {
        CustomerId: payload.CustomerId,
        VerifiedEmailYN: "Y",
        MemberType: "Premium Member",
        access_token: payload.access_token,
      })

      //executing function to close modal and form reset
      payload.emailVerifiedHandler()

      setNotification({
        type: "success",
        message: "Validated successfully",
      })
    } catch (err) {
      setNotification({
        type: "error",
        message: "Failed to Validate OTP Code",
        description: err.response?.data?.message ?? "Invalid verification code",
      })
    } finally {
      yield put(setValidateOtpLoading(false))
    }
  })
}

export function* changeOtpEmailAPI() {
  yield takeEvery(CHANGE_OTP_EMAIL, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token

    yield put(setUpdateEmailLoading(true))

    try {
      yield call(CustomerEmailService.updateEmailSetting, {
        Email: payload.Email,
        CustomerId: payload.CustomerId,
        MemberType: "Premium Member",
        access_token: payload.access_token,
      })

      setNotification({
        type: "success",
        message: "Change email successfully",
      })

      // const { data: sendEmailResponse } = yield call(
      //   CustomerEmailService.sendEmailVerificationCode,
      //   { Email: payload.Email, access_token: payload.access_token }
      // )

      // setNotification({
      //   type: "success",
      //   message: sendEmailResponse.message || "Success Send Email",
      // })

      payload.callback()
    } catch (err) {
      setNotification({
        type: "error",
        message: err.response?.data?.message ?? "An error occured",
      })
    } finally {
      yield put(setUpdateEmailLoading(false))
    }
  })
}

export function* validateCustomerEmailAPI() {
  yield takeEvery(VALIDATE_CUSTOMER_EMAIL, function* ({ payload }) {
    let auth_token = sessionStorage.getItem("auth_token")
    payload.access_token = auth_token

    yield put(setValidateCustomerEmailLoading(true))

    try {
      const { data } = yield call(
        CustomerEmailService.validateCustomerEmail,
        payload
      )

      if (data.status === "valid") {
        yield call(CustomerEmailService.updateEmailSetting, {
          CustomerId: payload.CustomerId,
          MemberType: payload.MemberType,
          VerifiedEmailYN: "Y",
          access_token: payload.access_token,
        })
      }

      setNotification({
        type: "success",
        message: "Validate customer email successfully",
      })
    } catch (err) {
      setNotification({
        type: "error",
        message: err.response?.data?.message ?? "An error occured",
      })
    } finally {
      yield put(setValidateCustomerEmailLoading(false))
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(sendEmailVerificationAPI),
    fork(validateOtpCodeAPI),
    fork(changeOtpEmailAPI),
    fork(validateCustomerEmailAPI),
  ])
}
