export const SET_CUSTOMER_LOADING = "SET_CUSTOMER_LOADING"
export const GET_LIST_CUSTOMER = "GET_LIST_CUSTOMER"
export const CREATE_CUSTOMER = "CREATE_CUSTOMER"
export const GET_RESULT_CUSTOMER = "GET_RESULT_CUSTOMER"
export const GET_CUSTOMER = "GET_CUSTOMER"
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const DELETE_CUSTOMER = "DELETE_CUSTOMER"
export const RESULT_CUSTOMER = "RESULT_CUSTOMER"
export const ALERT_CUSTOMER = "ALERT_CUSTOMER"
export const REMOVE_CUSTOMER_ALERT = "REMOVE_CUSTOMER_ALERT"
export const GET_CURRENT_CUSTOMER = "GET_CURRENT_CUSTOMER"
export const SET_CURRENT_CUSTOMER = "SET_CURRENT_CUSTOMER"
export const CLEAR_CURRENT_CUSTOMER = "CLEAR_CURRENT_CUSTOMER"
// TODO: delete functions
export const CREATE_CUSTOMER_SELF_SERVICE = "CREATE_CUSTOMER_SELF_SERVICE"
export const UPDATE_CUSTOMER_SELF_SERVICE = "UPDATE_CUSTOMER_SELF_SERVICE"
//
export const GET_CUSTOMER_MEMBERSHIP_DETAILS = "GET_CUSTOMER_MEMBERSHIP_DETAILS"
export const SET_CUSTOMER_MEMBERSHIP_DETAILS = "SET_CUSTOMER_MEMBERSHIP_DETAILS"
export const GET_CUSTOMER_MEMBERSHIP_MERGE_DETAILS =
  "GET_CUSTOMER_MEMBERSHIP_MERGE_DETAILS"
export const SET_CUSTOMER_MEMBERSHIP_MERGE_DETAILS =
  "SET_CUSTOMER_MEMBERSHIP_MERGE_DETAILS"
export const CLEAR_CUSTOMER_MEMBERSHIP_DETAILS =
  "CLEAR_CUSTOMER_MEMBERSHIP_DETAILS"
export const CUSTOMER_LOGIN = "CUSTOMER_LOGIN"
export const FIND_ONE_CUSTOMER_LOGIN = "FIND_ONE_CUSTOMER_LOGIN"
export const UPDATE_CUSTOMER_LOGIN = "UPDATE_CUSTOMER_LOGIN"
export const UPDATE_CUSTOMER_PASSWORD = "UPDATE_CUSTOMER_PASSWORD"
export const SET_CONFIRM_UPGRADE = "SET_CONFIRM_UPGRADE"
export const UPGRADE_TO_PREMIUM = "UPGRADE_TO_PREMIUM"
export const GET_CARD_LEVEL_DETAIL = "GET_CARD_LEVEL_DETAIL"
export const VERIFY_REFERRER = "VERIFY_REFERRER"
export const MERGE_PREMIUM_CUSTOMER_MEMBERSHIP =
  "MERGE_PREMIUM_CUSTOMER_MEMBERSHIP"
