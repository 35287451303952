import {
  CREATE_AUDIT_LOG,
  SET_CREATE_AUDIT_LOG,
  GET_LIST_AUDIT_LOG,
  SET_LIST_AUDIT_LOG,
} from "redux/constants/AuditLog"

export const createAuditLog = (data) => {
  return {
    type: CREATE_AUDIT_LOG,
    payload: data,
  }
}

export const setCreateAuditLog = (data) => {
  return {
    type: SET_CREATE_AUDIT_LOG,
    payload: data,
  }
}

export const getListAuditLog = (data) => {
  return {
    type: GET_LIST_AUDIT_LOG,
    payload: data,
  }
}

export const setListAuditLog = (data) => {
  return {
    type: SET_LIST_AUDIT_LOG,
    payload: data,
  }
}
