import React, { useState } from "react"
import { connect } from "react-redux"
import { Button } from "antd"
import { PlusCircleOutlined } from "@ant-design/icons"
import { createCustomerLeadSource } from "redux/actions/CustomerLeadSource"
import CustomerLeadSourceModal from "./modal/CustomerLeadSourceModal"

const CustomerLeadSourceAddButton = ({ createCustomerLeadSource }) => {
  const [showModal, setShowModal] = useState(false)
  const initialValues = {
    Code: "",
    Name: "",
    Type: "",
    Status: "Active",
  }
  const [data, setData] = useState(initialValues)

  const saveCustomerLeadSource = () => {
    createCustomerLeadSource({
      ...data,
      Code: data.Code?.toUpperCase(),
    })
  }

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        type="primary"
        icon={<PlusCircleOutlined />}
        block
      >
        Add Lead Source
      </Button>
      <CustomerLeadSourceModal
        title={"Create Lead Source"}
        visible={showModal}
        setShowModal={setShowModal}
        data={data}
        setData={setData}
        onSubmit={saveCustomerLeadSource}
      />
    </>
  )
}

const mapStateToProps = ({ customerLeadSource }) => {
  const { customerLeadSourceList } = customerLeadSource

  return { customerLeadSourceList }
}

const mapDispatchToProps = {
  createCustomerLeadSource,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerLeadSourceAddButton)
