import axios from "axios"
import { API_BASE_URL } from "configs/AppConfig"
// import
// LogService,
// { payload as logPayload } from "services/LogService"
// import { notification } from "antd"

import {
  AUTH_TOKEN,
  // REFRESH_TOKEN
} from "redux/constants/Auth"
// import { env } from "configs/EnvironmentConfig"

const apiService = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
  withCredentials: true,
})

// const payload = { ...logPayload }

// apiService.interceptors.request.use(
//   (req) => {
//     payload.ServiceName = req?.baseURL + req?.url
//     payload.Details = {
//       baseURL: req?.baseURL,
//       method: req?.method,
//       url: req?.url,
//       Payload: req?.data,
//     }
//     return req
//   },
//   (error) => {
//     notification.error({ message: "Request Error" })
//     Promise.reject(error)
//   }
// )

// const errorHandler = async (error) => {
//   let message = error?.response?.data?.message ?? "Server Error"
//   if (Array.isArray(message)) message = message[message.length - 1]
//   const status = error?.response?.status?.toString() ?? "500"
//   if (status !== "500") {
//     payload.Timestamp = new Date()
//     payload.Message = message
//     payload.Error = message
//     payload.StackTrace = error?.stack
//       .replace(/\\\\/g, "/")
//       .replace(/"/g, "")
//       .replace(/\n/g, "")
//     payload.Status = status
//     await LogService.createLog(payload)
//   }
//   return Promise.reject(error)
// }

// apiService.interceptors.response.use(
//   (response) => response,
//   (error) => errorHandler(error)
// )

apiService.interceptors.request.use(
  async (config) => {
    const access_token = sessionStorage.getItem(AUTH_TOKEN)
    if (access_token) {
      config.headers.Authorization = `Bearer ${access_token}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

apiService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      // const refreshToken = sessionStorage.getItem(REFRESH_TOKEN)
      try {
        // sessionStorage.setItem(AUTH_TOKEN, refreshToken)

        // const response = await axios.post(
        //   `${env.API_ENDPOINT_URL}/auth/refresh-token`,
        //   { refreshToken }
        // )
        // const { access_token, refresh_token } = response.data

        // sessionStorage.setItem(REFRESH_TOKEN, refresh_token)
        // apiService.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${access_token}`
        // return apiService(originalRequest)

        /*Redirecting Back to Login Page after token expired*/
        sessionStorage.clear()
        window.location.href = "/"

        return
      } catch (refreshError) {
        console.error("Refresh token failed", refreshError)
      }
    }
    return Promise.reject(error)
  }
)

export default apiService
